.upload-section {
    padding-top: 50px;
    padding-bottom: 50px;

    button[type="submit"],
    button[type="button"] {
        min-width: 250px;
        height: 50px;
        background-color: var(--rldn-teal);
        border-color:var(--rldn-teal);
        color: #FFF;

        &:hover {
            background-color: #FFF;
            color: var(--rldn-teal);
        }
    }

    button[type="submit"] {
        width: 100%;
    }
}