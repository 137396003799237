:root {
    --rldn-purple: #470054;
    --rldn-teal: #00757D;
}

body {
    margin: 0;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Joane Black';
    src: local('Joane'), url('./assets/fonts/Joane/Joane-Black.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    src: local('OpenSans'), url('./assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans Bold';
    src: local('OpenSans'), url('./assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff2');
}

@font-face {
    font-family: 'Grot Regular';
    src:local('Grot'), url('./assets/fonts/Grot/Grot-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Grot Bold';
    src:local('Grot'), url('./assets/fonts/Grot/Grot-Bold.woff2') format('woff2');
}