h1, h2, h3 {
    font-family: 'Grot Bold';
    color: var(--rldn-purple);
}

p {
    color: #212529;
    font-family: 'Grot Regular';
}

button {
    font-family: 'Grot Bold';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-width: 3px;
    border-radius: 0 12px 12px 12px;
    transition: all .5s;

    &:hover {
        border-radius: 12px 12px 12px 12px;
    }
}

.logo {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.copyright-notice {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 20px;
    font-family: 'Grot Regular' !important;

}

.api-message {
    text-align: center;

    p {
        margin-top: 1rem;
    }
}

button.amplify-button[data-variation=primary] {
    font-family: 'Grot Bold' !important;
    font-size: 18px !important;
    background: #00757D !important;

    &:hover {
        background: #00818A !important;
    }
}


input {
    font-family: 'Grot Regular' !important;
    font-size: 18px;
}
/* Grot10 reg size 18 for input text placeholder and grot10 bold size18 for button 
  */