.header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    border-bottom: 2px solid var(--rldn-purple);

    .logo {
        position: relative;
        display: block;
        width: 100%;
        max-width: 300px;
    }

    #sign-out-button {
        position: absolute;
        display: block;
        height: 38px;
        top: 20px;
        right: 15px;
        background-color: #FFF;
        border-color: var(--rldn-teal);

        &:hover {
            background-color: var(--rldn-teal);
            color: #FFF;

            .span {
                color: #FFF;;
            }
        }
    }
}