.uploader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    margin-bottom: 1rem;
    background-color: #00757d1a;
    border: 3px dashed var(--rldn-teal);
    border-radius: 24px 24px 0 24px;
    transition: border-color ease-in-out .35s;
    font-family: 'Grot Regular';
    svg {
        font-size: 100px;
        margin-bottom: 10px;
        opacity: 0.5;
        transition: opacity ease-in-out .35s;
    }

    span {
        font-size: 1.25rem;
        opacity: 0.5;
        transition: opacity ease-in-out .35s;
    }

    &.drag-active, &.file-accepted {
        border-color: rgb(0, 0, 0);

        span, svg {
            opacity: 1;
        }
    }
}