form#upload-form {

    .form-control {
        border-radius: 0px;
    }

    label {
        font-family: 'Grot Regular';
        margin-bottom: 5px;
        color: #212529;
    }

    input[type="text"], input[type="number"] {
        margin-bottom: 15px;
    }
}